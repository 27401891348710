import React from 'react';
import UnderLine from '../../icons/UnderLine';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PageLink from '../home/PageLink';
import {
  nextChapterContent,
  blackLines,
  chapterImage,
  chapterImageWrapper,
} from './NextChapter.module.scss';

const NextChapter = ({ next, color, btnText }) => {
  const { preview_img, slug, summary, title } = next;
  if (color === null) color = { accent: 'Lemon', theme: 'Light' };
  const { accent, theme } = color;
  return (
    <div className={`bg${theme} pagePadding componentPadding`}>
      <div
        className={`${blackLines} varColor${
          theme === 'Light' ? 'Black' : 'White'
        }`}
      >
        <UnderLine />
      </div>
      {next && (
        <div className={`${nextChapterContent} mt40 pb48`}>
          <div className={`${chapterImageWrapper} pl24 pr24 varColor${accent}`}>
            <GatsbyImage
              className={`${chapterImage}`}
              alt={preview_img.alternativeText}
              image={getImage(preview_img.localFile)}
            />
          </div>
          <div className={`mt40`}>
            <PageLink
              title={title}
              summary={summary}
              slug={slug}
              slugText={btnText ? btnText : 'Continue'}
              accent={accent}
              theme={theme}
              headerText={btnText ? btnText : 'Next'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NextChapter;

// extracted by mini-css-extract-plugin
export var nav = "ImageLayout-module--nav--361zr";
export var navHand = "ImageLayout-module--navHand--U2hp_";
export var menuLink = "ImageLayout-module--menuLink--265lJ";
export var activePage = "ImageLayout-module--activePage--3HyV3";
export var h1 = "ImageLayout-module--h1--1k_aE";
export var h2 = "ImageLayout-module--h2--3pr9j";
export var h3 = "ImageLayout-module--h3--2PIlg";
export var h4 = "ImageLayout-module--h4--3RP3E";
export var p = "ImageLayout-module--p--2V1IS";
export var lede = "ImageLayout-module--lede--3huu8";
export var handLarge = "ImageLayout-module--handLarge--32BIu";
export var quoteAttribution = "ImageLayout-module--quoteAttribution--1JSCd";
export var figcaption = "ImageLayout-module--figcaption--h3aMq";
export var handSmall = "ImageLayout-module--handSmall--3F0A-";
export var imageCredit = "ImageLayout-module--imageCredit--3Kj9m";
export var pageDescription = "ImageLayout-module--pageDescription--2ynEA";
export var exploreCircleText = "ImageLayout-module--exploreCircleText--fxGFC";
export var footer = "ImageLayout-module--footer--2wVze";
export var footerCredit = "ImageLayout-module--footerCredit--3F8pd";
export var buttonText = "ImageLayout-module--buttonText--2gW1E";
export var button = "ImageLayout-module--button--jAbaR";
export var textColorWhite = "ImageLayout-module--textColorWhite--2eP2P";
export var textColorBlack = "ImageLayout-module--textColorBlack--1eQun";
export var textColorDune = "ImageLayout-module--textColorDune--3uZRJ";
export var textColorDune85 = "ImageLayout-module--textColorDune85--3y796";
export var textColorTimberwolf = "ImageLayout-module--textColorTimberwolf--2C37Z";
export var semibold = "ImageLayout-module--semibold--M3j-J";
export var strong = "ImageLayout-module--strong--3MorZ";
export var noDecoration = "ImageLayout-module--noDecoration--1X-vH";
export var gatsbyFocusWrapper = "ImageLayout-module--gatsby-focus-wrapper--9bjZJ";
export var sixteenNineBox = "ImageLayout-module--sixteenNineBox--2dG7A";
export var fourThreeBox = "ImageLayout-module--fourThreeBox--2jRnr";
export var sixteenNineBoxInner = "ImageLayout-module--sixteenNineBoxInner--2AVPV";
export var componentWrapper = "ImageLayout-module--componentWrapper--kQfHp";
export var linedPaper = "ImageLayout-module--linedPaper--3mMbb";
export var btnDisabled = "ImageLayout-module--btnDisabled--Ox48K";
export var inlineIconCreditBgLight = "ImageLayout-module--inlineIconCreditBgLight--21psZ";
export var creditIcon = "ImageLayout-module--creditIcon--1WmEM";
export var bgLight = "ImageLayout-module--bgLight--1MZKW";
export var inlineIconCreditBgDark = "ImageLayout-module--inlineIconCreditBgDark--3h2lP";
export var bgDark = "ImageLayout-module--bgDark--UodzT";
export var svgColorWhite = "ImageLayout-module--svgColorWhite--22DEE";
export var shadowCollage = "ImageLayout-module--shadowCollage--1sBFv";
export var container = "ImageLayout-module--container--3DKHx";
export var detailsContainer = "ImageLayout-module--detailsContainer--JCGXw";
export var bgColor = "ImageLayout-module--bgColor--cH_3a";
export var bgPaper = "ImageLayout-module--bgPaper--NucKp";
export var imageContainer = "ImageLayout-module--imageContainer--MUNmp";
export var mainImage = "ImageLayout-module--mainImage--WodXR";
export var textContainer = "ImageLayout-module--textContainer---fOaa";
export var creditContainer = "ImageLayout-module--creditContainer--22EFQ";
export var penCircle = "ImageLayout-module--penCircle--207JD";
export var left1 = "ImageLayout-module--left1--14Z_v";
export var left2 = "ImageLayout-module--left2--2_AOc";
export var right1 = "ImageLayout-module--right1--3JSiw";
export var right2 = "ImageLayout-module--right2--2zUph";
export var fullWidth = "ImageLayout-module--fullWidth--2oznA";
export var captionWrapper = "ImageLayout-module--captionWrapper--3y4Ow";
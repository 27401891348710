import { graphql } from 'gatsby';
import React from 'react';
import AppStop from '../components/chapter/AppStop';
import Callout from '../components/chapter/Callout';
import Hero from '../components/chapter/Hero';
import ImageLayout from '../components/chapter/ImageLayout';
import NextChapter from '../components/chapter/NextChapter';
import Text from '../components/chapter/Text';
import Layout from '../Layout';

export default function Chapter({ data }) {
  const {
    title,
    sections,
    subtitle,
    order,
    part,
    title_svg,
    texture,
    lede,
    illustration,
    illustration_caption,
    color,
  } = data.strapiChapters;
  sections.sort((a, b) => (a.order > b.order ? 1 : -1));

  const allChapters = data.allStrapiChapters.nodes;
  const nextIndex = allChapters.map(({ order }) => order).indexOf(order) + 1;
  const nextChapter =
    nextIndex === allChapters.length
      ? data.strapiAbout
      : allChapters[nextIndex];

  let lastSectionColor = sections[sections.length - 1].color;

  return (
    <Layout
      footerBg={lastSectionColor.theme}
      title={title}
      seoImage={illustration}
    >
      <Hero
        title={title}
        subtitle={subtitle}
        part={part}
        titleSvg={title_svg}
        texture={texture}
        lede={lede}
        illustration={illustration}
        illustrationCaption={illustration_caption}
        color={color}
      />
      {sections?.map(({ components, color }) =>
        components?.map(
          (
            {
              strapi_component,
              attribution,
              body,
              collage,
              image_credit,
              layout,
              lead_in,
              limit_height,
              mask,
              stop_numbers,
            },
            index
          ) => {
            return {
              'chapter.image': (
                <ImageLayout
                  key={index}
                  imageCredit={image_credit}
                  mask={mask}
                  limitHeight={limit_height}
                  layout={layout}
                  color={color}
                />
              ),
              'chapter.text': (
                <Text body={body} key={index} color={color} collage={collage} />
              ),
              'chapter.callout': (
                <Callout
                  body={body}
                  key={index}
                  attribution={attribution}
                  color={color}
                  leadIn={lead_in}
                />
              ),
              'chapter.app-stop': (
                <AppStop
                  body={body}
                  key={index}
                  color={color}
                  imageCredit={image_credit}
                  stopNumbers={stop_numbers}
                />
              ),
            }[strapi_component];
          }
        )
      )}
      <NextChapter
        next={nextChapter}
        color={{
          accent: nextChapter.color.accent,
          theme: lastSectionColor.theme,
        }}
      />
    </Layout>
  );
}
export const query = graphql`
  query ($slug: String!) {
    strapiChapters(slug: { eq: $slug }) {
      order
      title
      subtitle
      lede
      part
      title_svg {
        localFile {
          svgData
        }
      }
      texture {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 60)
          }
        }
      }
      illustration {
        alternativeText
        width
        height
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            seo: gatsbyImageData(layout: FIXED, width: 1200)
          }
        }
      }
      illustration_caption
      color {
        accent
        theme
      }
      sections {
        order
        color {
          accent
          theme
        }
        components {
          body
          strapi_component
          collage {
            layout
            credit
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 60
                    breakpoints: [500, 750, 1080, 1366]
                  )
                }
              }
            }
            brightness
            contrast
          }
          attribution
          image_credit {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 60)
                }
              }
            }
            credit
            caption
            brightness
            contrast
          }
          layout
          lead_in
          limit_height
          mask {
            end
            duration
            frames
            start
            shape {
              alternativeText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 60)
                }
              }
            }
            image_sequence {
              alternativeText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 60
                    sizes: "(max-width: 599px) 4500px, 9000px"
                    breakpoints: [4500, 9000]
                  )
                }
              }
            }
          }
          stop_numbers
        }
      }
    }
    allStrapiChapters(sort: { fields: order, order: ASC }) {
      nodes {
        color {
          accent
        }
        preview_img {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 60)
            }
          }
        }
        slug
        title
        order
        summary
      }
    }
    strapiAbout {
      color {
        accent
      }
      preview_img {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 60)
          }
        }
      }
      slug
      title
      summary
    }
  }
`;

import React from 'react';

const PenCircle = () => {
  return (
    <svg
      viewBox="0 0 400 213"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M208.792 7.62462C183.901 -1.16384 155.834 2.87124 126.868 10.1048C94.9035 18.0873 43.7978 30.5193 13.1898 72.4957C-15.5245 111.875 21.8164 144.883 35.1128 155.592C96.6399 205.149 178.541 216.353 259.615 207.936C296.197 204.138 355.415 193.859 389.273 134.709C416.4 87.3191 366.905 60.5134 351.929 52.1009C284.32 14.1243 204.724 1.38568 123.153 7.22323C97.5088 9.05842 63.6551 8.40725 35.6638 28.3539C14.8475 43.1878 35.5243 58.7714 40.8706 64.3998"
          stroke="#a3323b"
          strokeWidth="3"
          strokeLinecap="round"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  );
};

export default PenCircle;

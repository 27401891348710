import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef, useState } from 'react';
import {
  accentColor,
  background,
  imgContainer,
  maskContainer,
  maskImage,
  maskSequence,
} from './ImageMask.module.scss';

gsap.registerPlugin(ScrollTrigger);

const ImageMask = ({ mask, accent, children }) => {
  const containerRef = useRef();
  const animRef = useRef();
  const [currentFramePos, setCurrentFramePos] = useState(0);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: `imgMask`,
        trigger: containerRef.current,
        start: `${mask?.start}% center`,
        end: `${mask?.end}}% center`,
        toggleActions: 'play none reverse reset',
        scrub: 1,
        //markers: true,
        onUpdate: (self) => {
          if (animRef.current) {
            let currentFrame = Math.round(self.progress * mask?.frames);
            setCurrentFramePos((currentFrame - 1) * 100);
          }
        },
      },
    });

    return () => {
      tl.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mask !== null && mask !== undefined) {
    const { shape, frames, image_sequence } = mask;
    return (
      <div ref={containerRef}>
        <div className={`posRel ${background}`}>
          <div className={`${maskContainer}`} ref={animRef}>
            <GatsbyImage
              image={getImage(image_sequence.localFile)}
              alt={''}
              className={`${maskSequence}`}
              //objectPosition={`${currentFramePos}% center`}
              imgStyle={{
                width: `${frames * 100}vw`,
                left: `-${currentFramePos}vw`,
                objectFit: 'fill',
              }}
            />
            <div className={`varBgColor varColor${accent} ${accentColor}`} />
            <GatsbyImage
              image={getImage(shape.localFile)}
              alt={''}
              className={`${maskImage}`}
            />
          </div>
          <div className={`${imgContainer}`}>{children}</div>
        </div>
      </div>
    );
  } else {
    return children;
  }
};

export default ImageMask;

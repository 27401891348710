import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { Fragment } from 'react';
import PenCircle from '../../icons/PenCircle';
import parseLinkOption from '../../utils/parseLinkText';
import {
  bgColor,
  bgPaper,
  captionWrapper,
  container,
  creditContainer,
  detailsContainer,
  fullWidth,
  imageContainer,
  left1,
  left2,
  mainImage,
  penCircle,
  right1,
  right2,
  textContainer,
} from './ImageLayout.module.scss';
import ImageMask from './ImageMask';

const ImageLayout = ({ imageCredit, mask, layout, color, limitHeight }) => {
  if (color === null || color === undefined)
    color = { accent: 'Lemon', theme: 'Light' };
  const { accent, theme } = color;
  const { image, credit, caption, brightness, contrast } = imageCredit;

  const getLayoutClass = () => {
    switch (layout) {
      case 'FULLWIDTH':
        return fullWidth;
      case 'RIGHT1':
        return `${right1}`;
      case 'RIGHT2':
        return `${right2}`;
      case 'LEFT1':
        return `${left1}`;
      case 'LEFT2':
      default:
        return `${left2}`;
    }
  };

  const aspectRatio =
    image && image.localFile
      ? image.localFile.childImageSharp.gatsbyImageData.height
      : 1;

  if (image === null) {
    return <div />;
  } else {
    return (
      <div className={`bg${theme}`}>
        <figure
          className={`componentColumns ${container} varColor${accent} ${getLayoutClass()} componentPadding`}
        >
          <div
            className={`${imageContainer}`}
            style={{
              width:
                aspectRatio > 1 && limitHeight
                  ? `clamp(0px, (100vh - 2rem) / ${aspectRatio}, 100% - 1.5rem)`
                  : 'auto',
              justifySelf:
                aspectRatio > 1 && limitHeight ? 'center' : 'stretch',
            }}
            // style={imageStyle}
          >
            <ImageMask mask={mask} accent={color.accent}>
              <GatsbyImage
                className={`${mainImage}`}
                // React doesn't let you use multiple functions in a single Filter, so one has to be applied to the wrapper
                style={{
                  filter: `brightness(${brightness || '100%'})`,
                }}
                imgStyle={{
                  filter: `contrast(${contrast || '100%'})`,
                }}
                image={getImage(image.localFile)}
                alt={''}
              />
            </ImageMask>
          </div>

          <figcaption className={`componentColumns ${detailsContainer}`}>
            {layout !== 'FULLWIDTH' && (
              <Fragment>
                <div className={`${bgColor}`}></div>
                <div className={`${bgPaper} linedPaper`}></div>
              </Fragment>
            )}
            <div
              className={`${textContainer} ${
                layout === 'FULLWIDTH' ? 'componentColumns' : 'textColorDune'
              }
              `}
            >
              <div className={`handSmall ${captionWrapper}`}>
                {caption && parse(caption)}
              </div>
              {credit && (
                <div className={`imageCredit ${creditContainer}`}>
                  <div className={`${penCircle}`}>
                    <PenCircle />
                  </div>
                  {parse(credit, parseLinkOption)}
                </div>
              )}
            </div>
          </figcaption>
        </figure>
      </div>
    );
  }
};

export default ImageLayout;

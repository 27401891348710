import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';
import parseLinkOption from '../../utils/parseLinkText';
import {
  bgMap,
  bgWrapper,
  collageImg,
  creditStyle,
  gridRows,
  imgOpacity,
  imgSizing,
  txtWrapper,
} from './AppStop.module.scss';

const AppStop = ({ body, color, imageCredit, stopNumbers }) => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "map-bg" }) {
        childImageSharp {
          gatsbyImageData(quality: 60)
        }
      }
    }
  `);

  if (color === null || color === undefined)
    color = { accent: 'Lemon', theme: 'Light' };
  const { theme } = color;

  return (
    <div
      className={`posRel componentWrapper componentPadding ${gridRows} bg${theme}`}
    >
      <div className={`${bgWrapper}`}>
        <GatsbyImage
          className={`${bgMap} ${theme === 'Dark' ? 'bgLight' : 'bgDark'}`}
          image={getImage(data.file.childImageSharp)}
          imgClassName={`multiply ${imgOpacity}`}
        />
      </div>

      <div
        className={`${txtWrapper} ${
          theme === 'Dark' ? 'textColorDune' : 'textColorTimberwolf'
        }`}
      >
        <h3 className={`h4 mb16`}>App Stop</h3>
        {body && parse(body, parseLinkOption)}
      </div>

      {imageCredit && (
        <GatsbyImage
          className={`${collageImg} shadowCollage`}
          alt={imageCredit.image.alternativeText}
          image={getImage(imageCredit.image.localFile)}
          imgClassName={`${imgSizing}`}
          // React doesn't let you use multiple functions in a single Filter, so one has to be applied to the wrapper
          style={{
            filter: `brightness(${
              imageCredit.brightness ? imageCredit.brightness : '100%'
            })`,
          }}
          imgStyle={{
            filter: `contrast(${
              imageCredit.contrast ? imageCredit.contrast : '100%'
            })`,
          }}
        />
      )}

      {/* This text should be the same for every instance so it doesn't, make sense to update it a dozen times every time the format changes     */}
      {/* {imageCredit.credit && (
        <div className={`${creditStyle} imageCredit`}>
          {parse(imageCredit.credit, parseLinkOption)}
        </div>
      )} */}
      <div className={`${creditStyle} imageCredit`}>
        <p>
          To download the mobile app visit{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://freeandequalproject.com"
            className={`creditIcon`}
          >
            freeandequalproject.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default AppStop;

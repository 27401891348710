// extracted by mini-css-extract-plugin
export var nav = "Text-module--nav--1m-Nc";
export var navHand = "Text-module--navHand--38RHP";
export var menuLink = "Text-module--menuLink--2Fpbx";
export var activePage = "Text-module--activePage--2RzfH";
export var h1 = "Text-module--h1--2hg3F";
export var h2 = "Text-module--h2--1Uvlw";
export var h3 = "Text-module--h3--3YkUw";
export var h4 = "Text-module--h4--TiXxx";
export var p = "Text-module--p--20AKv";
export var lede = "Text-module--lede--3BLQJ";
export var handLarge = "Text-module--handLarge--3ggEd";
export var quoteAttribution = "Text-module--quoteAttribution--CXz3F";
export var figcaption = "Text-module--figcaption--2tWWi";
export var handSmall = "Text-module--handSmall--2OpM4";
export var imageCredit = "Text-module--imageCredit--TWRGT";
export var pageDescription = "Text-module--pageDescription--2fdKm";
export var exploreCircleText = "Text-module--exploreCircleText--3DRvy";
export var footer = "Text-module--footer--3Ehib";
export var footerCredit = "Text-module--footerCredit--1ePax";
export var buttonText = "Text-module--buttonText--3o668";
export var button = "Text-module--button--26lkH";
export var textColorWhite = "Text-module--textColorWhite--3Qdzf";
export var textColorBlack = "Text-module--textColorBlack--2hgA0";
export var textColorDune = "Text-module--textColorDune--2BAO1";
export var textColorDune85 = "Text-module--textColorDune85--3cPwf";
export var textColorTimberwolf = "Text-module--textColorTimberwolf--W5Qrb";
export var semibold = "Text-module--semibold--3LxNG";
export var strong = "Text-module--strong--3HmNf";
export var noDecoration = "Text-module--noDecoration--h-G1s";
export var gatsbyFocusWrapper = "Text-module--gatsby-focus-wrapper--2pBAR";
export var sixteenNineBox = "Text-module--sixteenNineBox--19iLr";
export var fourThreeBox = "Text-module--fourThreeBox--1OC8U";
export var sixteenNineBoxInner = "Text-module--sixteenNineBoxInner--3GJse";
export var componentWrapper = "Text-module--componentWrapper--36f21";
export var linedPaper = "Text-module--linedPaper--3Mqm1";
export var btnDisabled = "Text-module--btnDisabled--3blR2";
export var inlineIconCreditBgLight = "Text-module--inlineIconCreditBgLight--eRBae";
export var creditIcon = "Text-module--creditIcon--3WpHu";
export var bgLight = "Text-module--bgLight--2iK92";
export var inlineIconCreditBgDark = "Text-module--inlineIconCreditBgDark--21W7W";
export var bgDark = "Text-module--bgDark--1gnKu";
export var svgColorWhite = "Text-module--svgColorWhite--30HXL";
export var shadowCollage = "Text-module--shadowCollage--3aIuH";
export var gridRows = "Text-module--gridRows--24Vc1";
export var textDark = "Text-module--textDark--2dgjs";
export var txtWrapper = "Text-module--txtWrapper--3VA3U";
export var noCollage = "Text-module--noCollage--2pF9E";
export var bgColor = "Text-module--bgColor--2j1om";
export var paperTexture = "Text-module--paperTexture--2WAz2";
export var imgTexture = "Text-module--imgTexture--3Ewb8";
export var collageImg = "Text-module--collageImg--3vRNj";
export var imgSizing = "Text-module--imgSizing--2QA7e";
export var creditWrapper = "Text-module--creditWrapper--2o5rT";
export var credit = "Text-module--credit--4lIBx";
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { Fragment } from 'react';
import parseLinkOption from '../../utils/parseLinkText';
import {
  bgColor,
  collageImg,
  creditWrapper,
  gridRows,
  imgSizing,
  imgTexture,
  noCollage,
  paperTexture,
  textDark,
  txtWrapper,
} from './Text.module.scss';

const Text = ({ body, collage, color }) => {
  if (color === null || color === undefined)
    color = { accent: 'Lemon', theme: 'Light' };
  const { accent, theme } = color;

  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "newspaper-texture" }) {
        childImageSharp {
          gatsbyImageData(quality: 60)
        }
      }
    }
  `);

  return (
    <div
      className={`posRel componentWrapper componentPadding ${
        collage ? gridRows : ''
      } bg${theme}`}
    >
      {/* Text */}
      <div className={`${txtWrapper} ${collage ? textDark : noCollage}`}>
        {body && parse(body, parseLinkOption)}
      </div>

      {/* If the component has a collage, show the color background and other elements */}
      {collage && (
        <Fragment>
          <div
            className={`varColor${accent} ${bgColor} ${collage.layout?.toLowerCase()}`}
          />
          {/* Display the lined paper on the left, or texture image on the right */}
          {collage.layout === 'LEFT' ? (
            <div className={`${paperTexture} linedPaper shadowCollage`} />
          ) : (
            <div className={`${imgTexture}`}>
              <GatsbyImage image={getImage(data.file.childImageSharp)} />
            </div>
          )}
          {/* Image and credit */}
          {collage.image && (
            <Fragment>
              <GatsbyImage
                className={`${collageImg} ${collage.layout?.toLowerCase()} shadowCollage`}
                alt={collage.image.alternativeText}
                image={getImage(collage.image.localFile)}
                imgClassName={`${imgSizing}`}
                // React doesn't let you use multiple functions in a single Filter, so one has to be applied to the wrapper
                style={{
                  filter: `brightness(${
                    collage.brightness ? collage.brightness : '100%'
                  })`,
                }}
                imgStyle={{
                  filter: `contrast(${
                    collage.contrast ? collage.contrast : '100%'
                  })`,
                }}
              />
              {collage.credit && (
                <div
                  className={`${creditWrapper} imageCredit ${collage.layout?.toLowerCase()}`}
                >
                  {parse(collage.credit, parseLinkOption)}
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Text;

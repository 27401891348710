import React from 'react';

const UnderLine = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 695 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 22.0673C46.6953 13.9047 92.9222 13.5969 137.29 13.267C171.706 13.0112 206.022 10.5197 240.452 10.3336C280.468 10.1173 320.556 9.42777 360.564 8.54094C436.673 6.85383 512.561 3 588.726 3C604.643 3 620.56 3 636.478 3C659.788 3 589.929 5.60301 566.643 6.6668C464.145 11.3493 361.603 15.6222 259.194 22.0673C213.984 24.9127 168.733 28.5126 123.6 32.4159C103.006 34.1969 82.3887 35.9273 61.8334 38.1198C55.2253 38.8246 35.5348 40.1134 42.1136 41.0532C50.0202 42.1827 59.06 41.1347 66.9671 41.1347C85.5189 41.1347 104.071 41.1347 122.622 41.1347C188.838 41.1347 254.998 38.6828 321.206 38.2827C388.89 37.8738 456.584 38.2012 524.27 38.2012C549.504 38.2012 625.102 40.4857 599.972 38.2012C515.333 30.5071 428.969 35.2678 344.103 35.2678C244.529 35.2678 145.027 36.9526 45.5361 40.8087C34.7226 41.2279 3.23588 46.7161 13.1044 42.2755C18.0318 40.0582 24.7426 39.7915 29.8906 39.0161C48.8057 36.167 67.749 33.2618 86.7683 31.1936C231.516 15.4532 376.625 22.7356 521.744 18.3191C574.54 16.7123 627.023 12.062 679.666 8.13351C682.226 7.94242 697.107 4.80296 690.177 9.60023C684.196 13.7413 676.156 14.5265 669.154 15.4671C598.128 25.0076 525.184 22.3357 453.703 23.4526C432.85 23.7784 412.093 23.3021 391.284 25.0008C385.92 25.4387 380.533 25.9438 375.557 27.9342"
        stroke="#A3323B"
        strokeWidth="4"
        strokeLinecap="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default UnderLine;

import parse from 'html-react-parser';
import React from 'react';
import UnderLine from '../../icons/UnderLine';
import {
  attributionText,
  componentContent,
  leadInStyle,
  redLines,
} from './Callout.module.scss';

const Callout = ({ attribution, body, color, leadIn }) => {
  if (color === null || color === undefined)
    color = { accent: 'Lemon', theme: 'Light' };
  const { theme } = color;
  if (attribution === null) attribution = '';

  return (
    <div className={`bg${theme} componentWrapper componentPadding`}>
      {leadIn && <div className={`${leadInStyle}`}>{parse(leadIn)}</div>}
      <figure className={`${componentContent}`}>
        <blockquote className={`mb16`}>{parse(body)}</blockquote>
        {attribution && (
          <figcaption className={`mb24 quoteAttribution ${attributionText}`}>
            —{parse(attribution)}
          </figcaption>
        )}
        <div className={`pb32 ${redLines}`}>
          <UnderLine />
        </div>
      </figure>
    </div>
  );
};

export default Callout;

const parseLinkOption = {
  replace: (domNode) => {
    if (domNode.name === 'a' && domNode.attribs.href.includes('http')) {
      domNode.attribs.target = '_blank';
      domNode.attribs.rel = 'noopener noreferrer';
      domNode.attribs.class = 'creditIcon';
    }
    return domNode;
  },
};

export default parseLinkOption;
